"use client";

import React, { useState } from "react";
import { FieldError, useForm, UseFormRegister } from "react-hook-form";
import * as yup from "yup";
import { AddNewsletter } from "@/app/lib/network/mail/AddNewsletter";
import { GetIssueResponse } from "@/app/lib/network/issue/GetIssue";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Symbol } from "@/app/lib/theme/Symbol/Symbol";
import { theme } from "@/app/lib/theme/theme";
import { sendGAEvent } from "@next/third-parties/google";

export const schema = yup.object().shape({
  email: yup
    .string()
    .required("To pole jest wymagane")
    .email("Błędny format adresu email."),
  customEmiteoAlertMailAccepted: yup
    .bool()
    .oneOf([true], "Należy zaakceptować to pole"),
});

type CheckBoxTypeProps = {
  data?: GetIssueResponse;
  register: UseFormRegister<yup.InferType<typeof schema>>;
  setValue: CallableFunction;
  error?: FieldError | FieldError[];
};

type NewsletterFormTypeProps = {
  checkBoxesComponent: React.FunctionComponent<CheckBoxTypeProps>;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
};

export const SimpleNewsletterForm: React.FunctionComponent<
  NewsletterFormTypeProps
> = ({ checkBoxesComponent, eventCategory, eventAction, eventLabel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async ({
    email,
    customEmiteoAlertMailAccepted,
  }: yup.InferType<typeof schema>) => {
    setIsLoading(true);

    AddNewsletter({
      email,
      customData: {
        emiteo_alert_mail_accepted: customEmiteoAlertMailAccepted,
      },
    })
      .then(() => {
        sendGAEvent("event", "buttonClicked", {
          value: `${eventCategory} | ${eventAction} | ${eventLabel}`,
        });
        setIsSuccess(true);
      })
      .catch((err) => {
        alert(
          "Wystąpił błąd przy próbie zapisu do list mailowej. Spróbuj ponownie.",
        );
        throw err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isSuccess) {
    return (
      <Box display="flex" gap={2}>
        <Symbol
          name="check_circle"
          size="34px"
          color={theme.palette.success.light}
        />
        <Typography variant="body1c" color="success.light">
          Dziękujemy za zapisanie się na nasz newsletter!
        </Typography>
      </Box>
    );
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  const CheckBoxes = checkBoxesComponent;

  const simpleNewsletterFormItems = [
    "dowiesz się o nowych prekampaniach emisjach",
    "otrzymasz darmowy e-book „Vademecum inwestora”",
    "powiadomimy Cię o najważniejszych zmianach na polskim rynku finansowania społecznościowego",
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{ color: "#666666" }}
    >
      <Typography variant="body1a">Zostaw nam swój e-mail, a:</Typography>
      {simpleNewsletterFormItems.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" gap={2}>
          <Symbol
            name="check"
            size="34px"
            color={theme.palette.success.light}
          />
          <Typography variant="body1a">{item}</Typography>
        </Box>
      ))}
      <Box
        component="form"
        id="newsletter-form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={2}
        mt={2}
      >
        <Box display="flex" gap={1}>
          <TextField
            sx={{ maxWidth: { md: 332 } }}
            label="E-mail"
            variant="outlined"
            size="medium"
            fullWidth
            type="text"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            autoComplete="off"
          />
          <Button
            variant="contained"
            sx={{ height: 56, borderRadius: 1 }}
            form="newsletter-form"
            size="medium"
            type="submit"
          >
            Wyślij
          </Button>
        </Box>
        <CheckBoxes
          register={register}
          setValue={setValue}
          error={errors.customEmiteoAlertMailAccepted}
        />
      </Box>
    </Box>
  );
};
