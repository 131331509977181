import moment from "moment-timezone";

export const displayDateFormat = "DD.MM.YYYY";
export const displayDateTimeFormat = "DD.MM.YYYY HH:mm";
export const displayTimeFormat = "HH:mm";

export const percentageUntilEnd = (startDate: Date, endDate: Date) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const now = moment();

  if (now.isBefore(start) || now.isAfter(end)) {
    return null;
  }

  const totalDuration = end.diff(start);
  const elapsed = now.diff(start);
  const percentage = (elapsed / totalDuration) * 100;

  if (Number.isNaN(percentage)) {
    return null;
  }

  return Number(percentage.toFixed(2));
};

export const getTimeUntilEnd = (endDate: Date) => {
  const now = moment();
  const end = moment(endDate);
  const diffInMinutes = end.diff(now, "minutes");

  if (diffInMinutes < 0) {
    return "";
  } else if (diffInMinutes === 1) {
    return `1 minuta`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minut`;
  } else if (diffInMinutes < 1440) {
    if (diffInMinutes / 60 < 2) {
      return `1 godzina`;
    } else if (
      diffInMinutes / 60 === 23 ||
      diffInMinutes / 60 === 22 ||
      diffInMinutes / 60 === 24
    ) {
      return `${Math.floor(diffInMinutes / 60)} godziny`;
    } else {
      return `${Math.floor(diffInMinutes / 60)} godzin`;
    }
  } else if (Math.floor(diffInMinutes / 1440) === 1) {
    return `1 dzień`;
  } else {
    return `${Math.floor(diffInMinutes / 1440)} dni`;
  }
};

// Returns the year from a date string
export const formatYear = (date: string) => {
  const parsedDate = moment(
    date,
    ["YYYY", "MM.YYYY", "DD.MM.YYYY", "DD.MM.YY", "YY", "D.MM.YYYY"],
    true,
  );
  return parsedDate.isValid() ? parsedDate.year() : "-";
};
