import type {} from "@mui/x-date-pickers/themeAugmentation";
declare module "@mui/material/styles" {
  interface Palette {
    checkbox: Palette["primary"];
  }

  interface PaletteOptions {
    checkbox?: PaletteOptions["primary"];
  }

  interface TypographyVariants {
    body1a: React.CSSProperties;
    body1b: React.CSSProperties;
    body1c: React.CSSProperties;
    body2a: React.CSSProperties;
    body2b: React.CSSProperties;
    body2c: React.CSSProperties;
    body3a: React.CSSProperties;
    body3b: React.CSSProperties;
    body3c: React.CSSProperties;
    body4a: React.CSSProperties;
    body4b: React.CSSProperties;
    body4c: React.CSSProperties;
  }

  // allow configuration using `createTheme()`
  interface TypographyVariantsOptions {
    body1a?: React.CSSProperties;
    body1b?: React.CSSProperties;
    body1c?: React.CSSProperties;
    body2a?: React.CSSProperties;
    body2b?: React.CSSProperties;
    body2c?: React.CSSProperties;
    body3a?: React.CSSProperties;
    body3b?: React.CSSProperties;
    body3c?: React.CSSProperties;
    body4a?: React.CSSProperties;
    body4b?: React.CSSProperties;
    body4c?: React.CSSProperties;
    body5a?: React.CSSProperties;
    body5b?: React.CSSProperties;
    body5c?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body1a: true;
    body1b: true;
    body1c: true;
    body2a: true;
    body2b: true;
    body2c: true;
    body3a: true;
    body3b: true;
    body3c: true;
    body4a: true;
    body4b: true;
    body4c: true;
    body5a: true;
    body5b: true;
    body5c: true;
    // Remove basic ones, unused
    body1: false;
    body2: false;
  }
}

declare module "@mui/material/Avatar" {
  interface AvatarPropsVariantOverrides {
    cardIcon: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    checkbox: true;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    contained: true;
  }
}

import { createTheme } from "@mui/material";
import {
  blue,
  green,
  nordlandLightBlue,
  purple,
  redError,
  searchingBlue,
} from "./colors";

export const baseTheme = createTheme({
  palette: {
    primary: {
      main: blue[400],
      dark: blue[600],
      light: blue[300],
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: purple[500],
      dark: purple[700],
      light: purple[300],
      contrastText: "#FFFFFF",
    },
    error: {
      main: redError[500],
      dark: redError[700],
      light: redError[300],
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FF4F00",
    },
    success: {
      main: green[600],
      dark: green[800],
      light: green[400],
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#2B44FF",
    },
    checkbox: {
      main: "#838CF2",
      dark: "#666666",
    },
    text: {
      primary: "#252525",
      secondary: "#25252599",
      disabled: "#25252561",
    },
    common: {
      black: "#252525",
      white: "#FFFFFF",
    },
    divider: "#0000001F",
  },
  typography: {
    fontFamily: ["Figtree"].join(","),
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: "100%",
      letterSpacing: "-1%",
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: "100%",
      letterSpacing: "-1%",
    },
    h3: {
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "100%",
      letterSpacing: "-1%",
    },
    h4: {
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: "100%",
      letterSpacing: "-1%",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "110%",
      letterSpacing: "-1%",
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: 700,
      lineHeight: "130%",
      letterSpacing: "-1%",
    },
    body1a: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "-1%",
    },
    body1b: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "-1%",
    },
    body1c: {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "150%",
      letterSpacing: "-1%",
    },
    body2a: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body2b: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body2c: {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body3a: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body3b: {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body3c: {
      fontSize: "0.875rem",
      fontWeight: 700,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body4a: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body4b: {
      fontSize: "0.75rem",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body4c: {
      fontSize: "0.75rem",
      fontWeight: 700,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body5a: {
      fontSize: "0.625rem",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body5b: {
      fontSize: "0.625rem",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    body5c: {
      fontSize: "0.625rem",
      fontWeight: 700,
      lineHeight: "150%",
      letterSpacing: "0%",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "175%",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "157%",
      letterSpacing: "0.1px",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "166%",
      letterSpacing: "0%",
      color: "#25252599",
    },
    overline: {
      textTransform: "none",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "160%",
      letterSpacing: "0.4px",
      color: "#25252599",
    },
  },
});

export const theme = createTheme(
  {
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: "white",
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: "xl",
        },
      },
      MuiAvatar: {
        variants: [
          {
            props: { variant: "cardIcon" },
            style: {
              background: nordlandLightBlue[100],
              width: "50px",
              height: "50px",
            },
          },
        ],
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body1a: "p",
            body1b: "p",
            body1c: "p",
            body2a: "p",
            body2b: "p",
            body2c: "p",
            body3a: "p",
            body3b: "p",
            body3c: "p",
            body4a: "p",
            body4b: "p",
            body4c: "p",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: { borderRadius: 8, border: "1px solid #e0e0e0" },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          // root: { maxWidth: '100px' }
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              backgroundColor: searchingBlue[50],
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: searchingBlue[50],
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              backgroundColor: searchingBlue[50],
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: nordlandLightBlue[100],
            },
          },
        ],
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            [baseTheme.breakpoints.up("md")]: {
              width: "500px",
            },
            width: "100%",
            padding: "16px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            borderRadius: "12px",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
            ":focus": {
              boxShadow: "none",
            },
          },
          sizeLarge: {
            fontSize: "0.9375rem",
            fontWeight: 600,
            paddingTop: baseTheme.spacing(1),
            paddingBottom: baseTheme.spacing(1),
            paddingLeft: baseTheme.spacing(4),
            paddingRight: baseTheme.spacing(4),
          },
          sizeMedium: {
            fontSize: "0.875rem",
            fontWeight: 600,
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: baseTheme.spacing(2),
            paddingRight: baseTheme.spacing(2),
          },
          sizeSmall: {
            fontSize: "0.8125rem",
            fontWeight: 600,
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "10px",
            paddingRight: "10px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: baseTheme.spacing(2),
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            paddingTop: baseTheme.spacing(2),
            paddingBottom: baseTheme.spacing(2),
            paddingLeft: baseTheme.spacing(4),
            paddingRight: baseTheme.spacing(4),
            [baseTheme.breakpoints.down("md")]: {
              paddingLeft: baseTheme.spacing(3),
              paddingRight: baseTheme.spacing(3),
            },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(4),
            gap: baseTheme.spacing(4),
            [baseTheme.breakpoints.down("md")]: {
              padding: baseTheme.spacing(3),
              gap: baseTheme.spacing(3),
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            padding: baseTheme.spacing(1),
            backgroundColor: searchingBlue[800],
            borderRadius: baseTheme.spacing(0.5),
          },
          arrow: {
            color: searchingBlue[800],
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            "&.Mui-checked": {
              color: baseTheme.palette.checkbox.dark,
            },
          },
        },
        defaultProps: {
          color: "checkbox",
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            alignItems: "flex-start",
            gap: baseTheme.spacing(1),
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.background.default,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            "&.Mui-error": {
              marginLeft: "5px",
              marginRight: "5px",
            },
          },
        },
      },
      MuiDatePicker: {
        defaultProps: {
          timezone: "UTC",
          format: "DD / MM / YYYY",
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: baseTheme.palette.checkbox.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: baseTheme.palette.checkbox.main,
            },
          },
          notchedOutline: {
            borderColor: baseTheme.palette.checkbox.main,
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            "&.Mui-completed": {
              color: baseTheme.palette.success.light,
            },
            "&.Mui-active": {
              color: baseTheme.palette.primary.main,
            },
            fontSize: "18px",
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontSize: "10px",
            fontWeight: 600,
            color: "rgba(133, 150, 174, 1)",
            "&.Mui-active": {
              color: baseTheme.palette.primary.main,
            },
            "&.Mui-completed": {
              color: "rgba(133, 150, 174, 1)",
            },
          },
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            paddingTop: "4px",
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            maxWidth: "none",
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          // error: Symbol Icon is not clicable
          // IconComponent: () => (
          //   <Symbol
          //     name="keyboard_arrow_down"
          //     style={{
          //       marginRight: "12px",
          //       pointerEvents: "none",
          //     }}
          //   />
          // ),
          MenuProps: {
            PaperProps: {
              variant: "outlined",
              sx: { backgroundColor: "white" },
            },
            style: {
              marginTop: baseTheme.spacing(1),
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: baseTheme.spacing(1),
          },
        },
        variants: [
          {
            props: { size: "small" },
            style: {
              "& .MuiChip-icon": {
                marginLeft: "7px",
              },
            },
          },
          {
            props: { size: "medium" },
            style: {
              "& .MuiChip-icon": {
                marginLeft: "11px",
              },
            },
          },
        ],
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(3),
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(3),
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(3),
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            width: "100%",
            borderRadius: "12px",
            backgroundColor: "rgba(248, 251, 253, 1)",
            "&:before": {
              display: "none",
            },
            "&:first-of-type": {
              borderRadius: "12px",
            },
            "&:last-of-type": {
              borderRadius: "12px",
            },
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            py: "6px",
            px: 2,
            borderRadius: 1,
          },
          message: {
            [baseTheme.breakpoints.down("md")]: {
              maxWidth: "50%",
            },
          },
          action: {
            paddingLeft: 0,
            marginRight: 0,
          },
        },
      },
    },
  },
  baseTheme,
);
