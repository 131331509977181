import restApi from "@/app/lib/services/restApi";

interface AddNewsletterProps {
  email: string;
  customData: {
    emiteo_alert_firstname?: string;
    emiteo_alert_lastname?: string;
    emiteo_alert_phone?: string;
    emiteo_alert_mail_accepted?: boolean;
    client_alert_mail_accepted?: boolean;
    client_alert_phone_accepted?: boolean;
  };
}

export const AddNewsletter = ({ email, customData }: AddNewsletterProps) =>
  restApi.post("/api/v1/mail/newsletter", {
    email,
    customData,
  });
