import { Color } from "@mui/material";

export const blue: Color = {
  50: "#ECE5FF",
  100: "#D9CCFF",
  200: "#B399FF",
  300: "#8E66FF",
  400: "#6833FF",
  500: "#4200FF",
  600: "#3500CC",
  700: "#280099",
  800: "#1A0066",
  900: "#0D0033",
  A100: "#D1A3FF",
  A200: "#B066FF",
  A400: "#8A33FF",
  A700: "#6A00FF",
};

export const green: Color = {
  50: "#ECF7EE",
  100: "#E3F2E5",
  200: "#C5E5C9",
  300: "#44AA52",
  400: "#3D994A",
  500: "#368842",
  600: "#33803E",
  700: "#296631",
  800: "#1F4C25",
  900: "#183B1D",
  A100: "#C4E9C9",
  A200: "#99D9A6",
  A400: "#6EC983",
  A700: "#52BF6C",
};

export const searchingBlue: Color = {
  50: "#F8FBFD",
  100: "#D3DBE5",
  200: "#B8C4D4",
  300: "#9EADC1",
  400: "#8596AE",
  500: "#6C7F9A",
  600: "#5A6A7F",
  700: "#495563",
  800: "#373F49",
  900: "#24292E",
  A100: "#D5DCEC",
  A200: "#B2C1DA",
  A400: "#8FA5C7",
  A700: "#748DB7",
};

export const nordlandLightBlue: Color = {
  50: "#F8FBFD",
  100: "#E0E8F0",
  200: "#CDDAE6",
  300: "#BACBDC",
  400: "#A8BDD1",
  500: "#96AEC5",
  600: "#85A0B9",
  700: "#7491AC",
  800: "#65839E",
  900: "#5B748B",
  A100: "#E1ECF5",
  A200: "#C5D8EA",
  A400: "#A9C3DF",
  A700: "#90B3D7",
};

export const redError: Color = {
  50: "#FFECE0",
  100: "#FFC9B3",
  200: "#FFA680",
  300: "#FF8250",
  400: "#FF6428",
  500: "#FF4F00",
  600: "#CC4000",
  700: "#993100",
  800: "#662100",
  900: "#331100",
  A100: "#FFD5BF",
  A200: "#FFB08F",
  A400: "#FF895F",
  A700: "#FF713F",
};

export const purple: Color = {
  50: "#E8E8FF",
  100: "#C5C4FF",
  200: "#A2A1FF",
  300: "#7F7EFF",
  400: "#6D6AFF",
  500: "#6460FF",
  600: "#504DCC",
  700: "#3D3A99",
  800: "#292766",
  900: "#161433",
  A100: "#C0BDFF",
  A200: "#9C99FF",
  A400: "#7875FF",
  A700: "#5E5BFF",
};
